import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const WhyParklotIsInexpensive = ({ location }) => {
  const title =
    "PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="パークロットでTwitterインスタントウィンを低コストで実施できる理由"
          pagedesc="インスタントウィンツールPARKLoTの安さの秘密と、導入時の費用を他社と比較して紹介します。安いだけではない充実の機能をご用意し、成果の出るキャンペーンが繰り返し実施可能です。無料相談も受付中。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/why-parklot-is-inexpensive.jpg"
          pagepath="/blog/why-parklot-is-inexpensive"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2022年3月4日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="こんなに“安い秘密は？皆様の疑問に答えます！"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/why-parklot-is-inexpensive.jpg"
                className="w-full"
              />
            </figure>
            <p>
              こんにちは！PARKLoT（パークロット）マーケティング担当の尾崎美幸です。
            </p>
            <p>
              今回は、「PARKLoTがインスタントウィンツールを低価格で提供する理由」「どうやってこの料金を実現しているのか？」についてご紹介します！
            </p>

            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    PARKLoTは低価格でキャンペーンし放題！
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    PARKLoTはなぜ低価格で月額制？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    PARKLoTはどうやってこの料金を実現している？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        1. 新規顧客獲得に予算をかけていない
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        2. 開発チームとのスムーズなコミュニケーション
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    自社アカウントでも低価格で初めてみたい！
                  </a>
                </li>
              </ol>
            </div>

            <h2 id="id1">PARKLoTは低価格でキャンペーンし放題！</h2>
            <p>
              PARKLoTのインスタントウィンツールは、月額66,000円です。他社と比べてもとてもお手頃で、破格な料金でご提供しています。
            </p>
            <p>
              下記からもわかるように、インスタントウィンツールは一般的に非常に高価な料金設定になっています。
            </p>
            <p>
              【料金比較】
              <br />
              ＜他社A＞　　　　　1,000,000円 / 回<br />
              ＜他社B＞　　　　　800,000円 / 回<br />
              ★＜PARKLoT＞　　66,000円 / 月額 <br />
            </p>

            <p>
              この金額だと、安易にキャンペーン施策を試すことができません。しかしPARKLoTだと、「10分の1以下の料金！」で「何度でも！」インスタントウィンキャンペーンの実施が可能です！
            </p>
            <p>
              あまりにも低価格なため、お客様からこのような声をよくいただきます。
            </p>
            <p>
              お客様A 「本当にこの料金ですか？」
              <br />
              お客様B
              「追加費用で色々必要なオプションを足すと、結局他社と変わらないくらいの金額になるんじゃないですか？」
              <br />
              お客様C 「年間契約必須なのでは？
              使ってない月も費用がかかるのはちょっと...」
            </p>
            <p>これらのご質問に答えさせていただきます。</p>
            <p>
              <strong>「本当に月額66,000円しかかかりません！」 </strong>
            </p>
            <p>
              １回のキャンペーンに何十万円もの予算がないお客様にも、安心してご利用いただくことができます。
            </p>
            <p>
              すぐに当選結果がわかると好評のインスタントウィンは標準機能としてご用意しております。外れた場合の応募者全員プレゼント、クーポン配布といった機能も追加料金無しの利用が可能です！
            </p>
            <p>
              さらに、より魅力的なキャンペーンを開催したいというお声にも対応いたします。
            </p>
            <p>よくご依頼されるオプションプランとしては</p>
            <p>
              ‹ギフトコード自動配布›　　　10,000円 / 月 + 手数料 配布額の10％
              <br />
              ‹ハッシュタグキャンペーン›　10,000円 / 月<br />
              ‹スクラッチ機能›　　　　　　50,000円 / 回
            </p>
            <p>
              と、お客様に合わせたカスタマイズをした場合でも、圧倒的な低価格でご利用いただくことができます！
            </p>
            <p>
              また、PARKLoTでは3ヶ月〜のご契約を基本としているため、無駄なコストを抑えてキャンペーンを実施できます！
            </p>
            <h2 id="id2">PARKLoTはなぜ低価格で月額制？</h2>
            <p>なぜPARKLoTはこの料金でご提供するのか...。</p>
            <p>
              それは、
              <strong>
                「SNSマーケティングを気軽に何度でも実施することで、SNSマーケティングの力を実感していただきたい」
              </strong>
              からです！
            </p>
            <p>
              やりたい施策があっても、ツールが高いと予算が取り辛く、一度の施策で成功する保証もないため、なかなか手を出すことができません。「時間をかけて企画したインスタントウィンキャンペーンを何十万円もかけてやったけど、不発に終わった...。」なんて悲惨な結果になったら、目も当てられませんよね。
            </p>
            <p>PARKLoTではこのもどかしさを解消します。</p>
            <p>
              PARKLoTは、月額制で何度でもインスタントウィンキャンペーンを実施することができます。そのため、SNSマーケティングのアイデアを思いつたら、キャンペーンを実施し、改善点をまた次のキャンペーンに生かすことができるのです。
            </p>
            <p>
              このようにして、試行錯誤しながらお客様に効果のあるインスタントウィンキャンペーンを何度も実施していただくことによって、SNSでファンを増やしていただきたいと考えています。
            </p>
            <p>すると、下記のようなご質問をいただきます。</p>
            <p>
              お客様A　「D社と比べ機能が少ないのですか？」
              <br />
              お客様B　「他社と比べると品質が悪いから安いのですか？」
            </p>
            <p>
              <strong>答えは「No」です！</strong>
            </p>
            <p>
              PARKLoTでは、インスタントウィンツールを提供している会社と同等、またはそれ以上の機能を扱っています。他社と比べ機能が少ないわけではございませんので、ご安心ください。
            </p>

            <h2 id="id3">PARKLoTはどうやってこの料金を実現している？</h2>
            <p>PARKLoTがこの低価格を実現できている理由は以下の2点です。</p>
            <h3 id="id4">1. 新規顧客獲得に予算をかけていない</h3>
            <p>
              PARKLoTでは、ありがたいことに多くのお客様に繰り返し使っていただいており、リピート率を高い水準で維持できています。
            </p>
            <p>
              そのため、新規顧客獲得に多額な予算をかける必要が無く、その予算分を料金に上乗せする必要がないため低価格を実現できています。
            </p>
            <h3 id="id5">2. 開発チームとのスムーズなコミュニケーション</h3>
            <p>PARKLoTのメンバーの9割が『開発者』または『元開発者』です。</p>
            <p>
              一般的な同業者様ですと、お客様と直接商談する営業部門と開発部門が分かれています。そのため、お客様のご要望を理解しながら機能開発を行うのに大きなコミュニケーションコストがかかってしまい、その分が機能開発費にプラスオンされてしまいます。
            </p>
            <p>
              開発メンバーが9割のPARKLoTではそのようなことは起きません。ここが低価格を実現できる理由の2つ目です。
            </p>

            <h2 id="id6">自社アカウントでも低価格で初めてみたい！</h2>

            <p>
              PARKLoTでは、「インスタントウィンキャンペーンはPDCAサイクルを回して実施することが重要」だと考えています。お客様に、SNSマーケティングの効果を実感していただきたい！という思いから、満足できる機能を低価格でご提供しております。
            </p>
            <p>
              この記事を読んで、自社の公式アカウントでも、さまざまなキャンペーンを繰り返し実施したいと思われた方は、お気軽にお問い合わせください！
            </p>
            <p>
              初めてPARKLoTをご利用いただく方には、無料で1時間のキャンペーン企画のご相談を承っております。料金の詳細や機能の説明も含め、貴社に最適なキャンペーンのご提案をさせていただきます。
            </p>
            <a
              href="https://www.park-lot.com/download/service"
              target="_blank"
              rel="noreferrer"
            >
              お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
            </a>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default WhyParklotIsInexpensive
